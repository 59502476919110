import { useSelector } from "react-redux";

import { 
    selectCurrentVolumeTab,
    selectFilteredDevices,
} from "../../reducers/compare.js";

import ConfigsTabItem from "./ConfigsTabItem.js";

function VolumeTab() {
    const tab = useSelector(selectCurrentVolumeTab);
    const filteredDevices = useSelector(selectFilteredDevices);

    const isExist = filteredDevices.findIndex(device => device.name === tab.name) !== -1;

    if (tab.name === null || !isExist) return;

    return (
        <div className="filter-box">
            { 
                tab.items.map(({ version, ids }, i) => (
                    <ConfigsTabItem key={i} ids={ids} name={tab.name} version={version} />
                ))
            }
        </div>
    );
}

export default VolumeTab;
