import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

function DevicesListAsideHeader({ name, menuStatus }) {

    return (
        <div 
            className={`name-item ${menuStatus}`}
            
        >
            {name.length > 28 ? `${name.slice(0, 28)}...` : name} 
            {name.length > 28 ? <span className="tooltip">{name}</span> : "" } 

            <span style={{ paddingLeft: '10px'}}>
                <FontAwesomeIcon 
                    icon={menuStatus === 'closed' ? faAngleDown : faAngleUp}  
                    size="xs" 
                />
            </span>
        </div>
    );
}

export default DevicesListAsideHeader;
