import { useDispatch } from "react-redux";

import { setConfigMenuStatus } from "../../../reducers/devices.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

function ConfigListVolumesHeader({ version, name, configMenuStatus }) {
	const dispatch = useDispatch();

    const handleConfigMenuClick = (e) => {
        e.stopPropagation();

        if (configMenuStatus === 'closed') 
            dispatch(setConfigMenuStatus({ configMenuStatus: 'opened', name, version })); 
        else if (configMenuStatus === 'opened') 
            dispatch(setConfigMenuStatus({ configMenuStatus: 'closed', name, version })); 
    }

    return (
        <div 
            className={`config-header ${configMenuStatus}`}
            onClick={handleConfigMenuClick}
        >
            Config {version}
            <span style={{ paddingLeft: '10px'}}>
                <FontAwesomeIcon 
                    icon={configMenuStatus === 'closed' ? faAngleDown : faAngleUp}  
                    size="xs" 
                />
            </span>
        </div>
    );
}

export default ConfigListVolumesHeader;
