import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPenToSquare, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import { selectUserRole } from "../../../reducers/auth.js";

function ConfigTitle({ version, isVolumeListOpen, setIsVolumeListOpen, handleAddNewVolume, handleEditConfig }) {
    const userRole = useSelector(selectUserRole);


    return (
        <div 
            className='config-title' 
        >
            <div 
                className='config-version'
                onClick={() => setIsVolumeListOpen(!isVolumeListOpen)}
            >
                Config version {version}
                <span style={{ float: 'right', paddingLeft: '10px'}}>
                    <FontAwesomeIcon 
                        icon={isVolumeListOpen ? faAngleDown : faAngleUp}  
                        size="xs" 
                    />
                </span>
            </div>

            { userRole === 'admin' &&
                <div 
                    className='config-button edit-config' 
                    onClick={handleEditConfig}
                >
                    <span>
                        <FontAwesomeIcon icon={faPenToSquare}  size="lg" /> Edit Config
                    </span>
                </div>
            }
            { userRole === 'admin' &&
                <div 
                    className='config-button add-new-volume' 
                    onClick={handleAddNewVolume}
                >
                    <span>
                        <FontAwesomeIcon icon={faPlusCircle}  size="lg" /> Add Volume
                    </span>
                </div>
            }
        </div>
    )
}

export default ConfigTitle;
