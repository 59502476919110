import { useSelector } from "react-redux";

import { 
    selectMeasurements 
} from "../../reducers/measurements.js";
import { 
	selectDevices,
	selectCurrentDeviceId
} from "../../reducers/devices.js";

import FilesTable from "./FilesTable.js";

function CalculationsTable() {
    const measurements = useSelector(selectMeasurements);
    
    const devices = useSelector(selectDevices);
    const deviceId = useSelector(selectCurrentDeviceId);

    let content;

    const measure = measurements.find(({ id }) => id === deviceId);
    const device = devices.find(({ id }) => id === deviceId);
    
    if (typeof measure === 'undefined' || typeof device === 'undefined') {
        content = <div className="something-went-wrong">No Devices for this filter 😓</div>
    } else {
        content = <FilesTable  file={measure} />
    }

    return (
        <section className='calculations-table'>
            {content}
        </section>
    );
}

export default CalculationsTable;
