
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice, current } from '@reduxjs/toolkit';
import { 
    httpPostDevice, 
    httpGetDevices, 
    httpPutDevice,
    httpDeleteDevice,
} from '../utils/network.js';
import { groupDevicesBySameName } from '../utils/utils.js';

const getFilteredDevices = (devices, filter) => {
    const categoriesObj = {
        'All': 'All',
        'Mini': 'xs',
        'Small': 's',
        'Medium': 'm',
        'Large': 'l',
        'Extra Large': 'xl'
    }

	if (!devices.length) return []
    
	return devices.filter(({ company, category }) => 
        company.toLowerCase() === filter.company.toLowerCase() && 
            (filter.category === 'All' || category === categoriesObj[filter.category])
    )
}

const initialState = {
    devices: [],
    groupedDevices: [],
    companies: [],
    filter: {
        company: '',
        category: 'All',
        devices: []
    },
    currentDevice: {
        id: 0,
        name: ''
    },
    categoriesArray: ['All', 'Mini', 'Small', 'Medium', 'Large', 'Extra Large'],
    categoriesObject: {
        'All': 'All',
        'xs': 'Mini',
        's': 'Small',
        'm': 'Medium',
        'l': 'Large',
        'xl': 'Extra Large'
    },
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
}

const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        setFilter(state, action) {
            const { company, category, deviceId } = action.payload;
            const devices = getFilteredDevices(state.groupedDevices, { company, category })

            state.filter = {
                company,
                category,
                devices
            }

            // device id equal 0 means that choosing first device of the filtered list and not specific device
            if (devices.length) {
                state.currentDevice.id = deviceId === 0 ? devices[0].configs[0].ids[0].id : deviceId;

                const indexes = { deviceIndex: null, configIndex: null  };

                for (let i = 0; i < state.filter.devices.length; i++) {
                    for (let j = 0; j < state.filter.devices[i].configs.length; j++) {
                        for (let k = 0; k < state.filter.devices[i].configs[j].ids.length; k++) {
                            if (state.filter.devices[i].configs[j].ids[k].id === state.currentDevice.id) {
                                indexes.deviceIndex = i;
                                indexes.configIndex = j;

                                break;
                            }
                        }

                        if (indexes.deviceIndex !== null) break;
                    }

                    if (indexes.deviceIndex !== null) break;
                }

                for (let i = 0; i < state.filter.devices.length; i++) {
                    state.filter.devices[i].menuStatus = i === indexes.deviceIndex ? 'opened' : 'closed';

                    for (let j = 0; j < state.filter.devices[i].configs.length; j++) {
                        if (i === indexes.deviceIndex) {
                            state.filter.devices[i].configs[j].configMenuStatus = j === indexes.configIndex ? 'opened' : 'closed';
                        } else {
                            state.filter.devices[i].configs[j].configMenuStatus = 'closed';
                        }
                    }
                }
            }
            else {
                state.currentDevice.id = 0;
            }
        },
        setCurrentDeviceId(state, action) {
            state.currentDevice.id = action.payload;
        },
        setCurrentDeviceName(state, action) {
            state.currentDevice.name = action.payload;
        },
        addDevicesOption(state, action) {
            const { optionName, optionValue } = action.payload;

            state.devices = state.devices.map(device => {
                device[optionName] = optionValue;
                return device
            })
        },
        setLineColor(state, action) {
            const { color, id } = action.payload;

            state.devices = state.devices.map(device => {
                if (device.id === id) device.lineColor = color;
                return device
            })
            
            state.groupedDevices = state.groupedDevices.map(device => {
                device.configs.forEach(config => {
                    config.ids.forEach(el => {
                        if (el.id === id) el.lineColor = color;
                    })

                });
                
                return device
            })
        },
        setMenuStatus(state, action) {
            const { menuStatus, name } = action.payload;

            const deviceIndex = state.filter.devices.findIndex(device => name === device.name);

            state.filter.devices[deviceIndex].menuStatus = menuStatus;
        },
        setConfigMenuStatus(state, action) {
            const { name, version, configMenuStatus  } = action.payload;

            const deviceIndex = state.filter.devices.findIndex(device => name === device.name);
            const configIndex = state.filter.devices[deviceIndex].configs.findIndex(config => config.version === version);

            state.filter.devices[deviceIndex].configs[configIndex].configMenuStatus = configMenuStatus;
        }
    },
    extraReducers: (builder) => {
        
        builder
            .addCase(getDevices.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getDevices.fulfilled, (state, action) => {
                state.status = 'succeded'
                state.devices = action.payload;

                const companies = Array.from(new Set(action.payload.map(({ company }) => company))).sort((a, b) => a.localeCompare(b));
                state.companies = ['All',...companies];
                state.filter.company = companies[0].toLowerCase();
                
                state.groupedDevices = groupDevicesBySameName(action.payload);
                
                const filteredDevices = getFilteredDevices(state.groupedDevices, { company: companies[0], category: 'All' });
                
                if (filteredDevices.length) {
                    state.filter.devices = filteredDevices;
                    state.currentDevice.id = filteredDevices[0].configs[0].ids[0].id;
                }
                
                // const deviceIndex = state.filter.devices.findIndex(device => {
                //     for (let i = 0; i < device.ids.length; i++) {
                //         if (device.ids[i].id === state.currentDevice.id)
                //             return true;
                //     }
    
                //     return false;
                // });

                state.filter.devices[0].menuStatus = 'opened';   
                state.filter.devices[0].configs[0].configMenuStatus = 'opened';
            })
            .addCase(getDevices.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
// ******************************************************************
            .addCase(registerDevice.pending, (state, action) => {
                state.status = 'loading';
            }) 
            .addCase(registerDevice.fulfilled, (state, action) => {
                let [ id, device ] = action.payload;

                device.id = id;
                device.size = +device.size;
                device.volume = +device.volume;
                delete device.measurements;

                const companyIndex = state.companies.findIndex(item => item === device.company);

                if (companyIndex === -1) {
                    state.companies.push(device.company);
                }

                state.devices.push(device);
                state.groupedDevices = groupDevicesBySameName(state.devices);

                state.status = 'succeded';
            })
            .addCase(registerDevice.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message
            })
// ******************************************************************
            .addCase(registerManyDevices.pending, (state, action) => {
                state.status = 'loading';
            }) 
            .addCase(registerManyDevices.fulfilled, (state, action) => {
                let [ ids, devices ] = action.payload;
                
                for (let i = 0; i < devices.length; i++) {
                    devices[i].id = ids[i];
                    devices[i].size = +devices[i].size;
                    devices[i].volume = +devices[i].volume;
                    delete devices[i].measurements;
    
                    state.devices.push(devices[i]);
                }

                const companyIndex = state.companies.findIndex(item => item === devices[0].company);
    
                if (companyIndex === -1) {
                    state.companies.push(devices[0].company);
                }

                state.groupedDevices = groupDevicesBySameName(state.devices);
                
                state.status = 'succeded';
            })
            .addCase(registerManyDevices.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message
            })
// ******************************************************************
            .addCase(registerDraftDevice.pending, (state, action) => {
                state.status = 'loading';
            }) 
            .addCase(registerDraftDevice.fulfilled, (state, action) => {
                let [ id, device ] = action.payload;
                
                device.id = id;
                device.size = +device.size;
                device.volume = +device.volume;
                delete device.measurements;

                const companyIndex = state.companies.findIndex(item => item === device.company);

                if (companyIndex === -1) {
                    state.companies.push(device.company);
                }

                state.devices.push(device);
                state.groupedDevices = groupDevicesBySameName(state.devices);
                state.status = 'succeded';
            })
            .addCase(registerDraftDevice.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message
            })
// ******************************************************************
            .addCase(updateDevice.pending, (state, action) => {
                state.status = 'loading';
            }) 
            .addCase(updateDevice.fulfilled, (state, action) => {
                const [ deviceId, device ] = action.payload;

                const idIndex = current(state.devices).findIndex(device => device.id === deviceId);

                if (idIndex > -1) {
                    state.devices[idIndex].name = device.name;
                    state.devices[idIndex].company = device.company;
                    state.devices[idIndex].size = +device.size;
                    state.devices[idIndex].category = device.category;
                    state.devices[idIndex].volume = +device.volume;
                    state.devices[idIndex].amp_config = device.amp_config;

                    state.groupedDevices = groupDevicesBySameName(state.devices);
                } else {
                    console.log('something went wrong')
                }

                state.status = 'succeded';
            })
            .addCase(updateDevice.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
// ******************************************************************
            .addCase(updateDeviceGeneralSettings.pending, (state, action) => {
                state.status = 'loading';
            }) 
            .addCase(updateDeviceGeneralSettings.fulfilled, (state, action) => {
                const [ devicesToUpdate, oldDevice ] = action.payload;
                
                for (let i = 0; i < devicesToUpdate.length; i++) {
                    const idIndex = current(state.devices).findIndex(device => device.id === devicesToUpdate[i].id);

                    if (idIndex > -1) {
                        state.devices[idIndex].name = oldDevice.name;
                        state.devices[idIndex].company = oldDevice.company;
                        state.devices[idIndex].size = +oldDevice.size;
                        state.devices[idIndex].category = oldDevice.category;
                        state.devices[idIndex].lineColor = devicesToUpdate[i].lineColor;
                    } else {
                        console.log('something went wrong')
                    }
                }

                state.groupedDevices = groupDevicesBySameName(state.devices);

                state.status = 'succeded';
            })
            .addCase(updateDeviceGeneralSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
// ******************************************************************
            .addCase(deleteDevice.pending, (state, action) => {
                state.status = 'loading';
            }) 
            .addCase(deleteDevice.fulfilled, (state, action) => {
                const deviceId = action.payload;

                const idIndex = state.devices.findIndex(device => device.id === deviceId);
                const company = state.devices[idIndex].company;
                const companyDevices = state.devices.filter(device => device.company === company);

                state.devices.splice(idIndex, 1);
                state.groupedDevices = groupDevicesBySameName(state.devices);
                
                const isLastDeviceInCompany = companyDevices.length === 1;

                if (isLastDeviceInCompany) {
                    const companyIndex = state.companies.findIndex(item => item === company);
                    state.companies.splice(companyIndex, 1);
                }
                
                state.status = 'succeded';
            })
            .addCase(deleteDevice.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message
            })
    },
})

export const selectCompanies = state => state.devices.companies;
export const selectSizeCategoriesObject = state => state.devices.categoriesObject;
export const selectSizeCategoriesArray = state => state.devices.categoriesArray;
export const selectDevicesStatus = state => state.devices.status;
export const selectDevciesError = state => state.devices.error;
export const selectDevicesFilter = state => state.devices.filter;
export const selectFilteredDevices = state => state.devices.filter.devices;
export const selectCurrentDeviceId = state => state.devices.currentDevice.id;
export const selectCurrentDeviceName = state => state.devices.currentDevice.name;
export const selectDevices = state => state.devices.devices;
export const selectGroupedDevices = state => state.devices.groupedDevices;
export const selectLastDeviceId = state => {
    if (state.devices.devices.length) {
        return state.devices.devices[state.devices.devices.length - 1].id
    } else 
        return 0
}
export const selectLineColorById = (id) => (state) => state.devices.devices.find(device => device.id === id).lineColor;

export const registerDevice = createAsyncThunk(
    'devices/registerDevice',
    async (device) => {
        return [ await httpPostDevice(device, 'register'), device ]
    }
)

export const registerManyDevices = createAsyncThunk(
    'devices/registerManyDevices',
    async (devices) => {
        const ids = [];

        for (const device of devices) {
            const id = await httpPostDevice(device, 'register');

            ids.push(id);
        }

        return [ ids, devices ]
    }
)

export const registerDraftDevice = createAsyncThunk(
    'devices/registerDraftDevice',
    async (device) => {
        return [ await httpPostDevice(device, 'register-draft'), device ]
    }
)

export const updateDevice = createAsyncThunk(
    'devices/updateDevice', 
    async (device) => {
    return [ await httpPutDevice(device, `device/${device.id}`), device ]
})

export const updateDeviceGeneralSettings = createAsyncThunk(
    'devices/updateDeviceGeneralSettings', 
    async (device) => {
    return [ await httpPutDevice(device, 'device-settings'), device ]
})

export const updateDeviceConfig = createAsyncThunk(
    'devices/updateDeviceGeneralSettings', 
    async (device) => {
    return [ await httpPutDevice(device, 'device-config'), device ]
})

export const getDevices = createAsyncThunk(
    'devices/getDevices', 
    async () => {
    return await httpGetDevices()
})

export const deleteDevice = createAsyncThunk(
    'devices/deleteDevice', 
    async (id) => {
    return await httpDeleteDevice(id)
})

export const { 
    setFilter, 
    setCurrentDeviceId, 
    setCurrentDeviceName,
    addDevicesOption, 
    setLineColor,
    setMenuStatus,
    setConfigMenuStatus
} = devicesSlice.actions;

export default devicesSlice.reducer;
