import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { 
    setFilter, 
    selectSizeCategoriesObject
} from '../../../reducers/devices.js';
import { toLowerCaseAndReplaceSymbolsWithDash } from "../../../utils/utils.js";

import { selectMeasurements } from "../../../reducers/measurements.js";
import ConfigTitle from './ConfigTitle.js';
import VolumeListItem from './VolumeListItem.js';

function AmplifierConfig({ ids, version, device, setClicked, setClickedDevice, setClickedDeviceVolume, setPoints }) {
    const [ isVolumeListOpen, setIsVolumeListOpen ] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const categories = useSelector(selectSizeCategoriesObject);
    const measurements = useSelector(selectMeasurements);

    const handleTitleClick = (id, volume) => (e) => {

        let newFilter = {
            company: device.company,
            category: categories[device.category],
            deviceId: id
        };

		dispatch(setFilter({
			...newFilter
		}));

        const companyName = toLowerCaseAndReplaceSymbolsWithDash(device.company);
        const speakerName = toLowerCaseAndReplaceSymbolsWithDash(device.name);
        const speakerVolume = `volume-${volume}`;
        const speakerConfig = `config-${version}`;

        navigate(`/calculations/${companyName}/${speakerName}/${speakerConfig}/${speakerVolume}`);
    }

    const handleEditButton = (id, volume) => (e) => {
        const measure = measurements.find(m => m.id === id);

        navigate('/edit-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                volume: volume,
                id: id,
                amp_config: version,
                measurements: measure,
                header: 'Edit Device'
            }
        }})
    }

    const handleDeleteButton = (id, volume) => (e) => {
        e.stopPropagation();
        e.preventDefault();

        setClicked(true);
        setClickedDevice(id);
        setClickedDeviceVolume(volume);
        setPoints({
            x: e.pageX,
            y: e.pageY
        });
    }

    const handleAddNewVolume = (e) => {

        navigate('/edit-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                amp_config: version,
                header: 'Add New Volume'
            }
        }})
    }

    const handleEditConfig = (e) => {

        navigate('/edit-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                amp_config: version,
                header: 'Edit Config'
            }
        }})
    }

    return (
        <div className='amplifier-config-container'>
            <ConfigTitle 
                version={version}
                isVolumeListOpen={isVolumeListOpen}
                setIsVolumeListOpen={setIsVolumeListOpen}
                handleAddNewVolume={handleAddNewVolume}
                handleEditConfig={handleEditConfig}
            />
            {  isVolumeListOpen && 
                ids.map(({ id, volume }) => 
                    <VolumeListItem 
                        key={id}
                        id={id}
                        volume={volume}
                        device={device}
                        handleTitleClick={handleTitleClick}
                        handleDeleteButton={handleDeleteButton}
                        handleEditButton={handleEditButton}
                    />
                )
            }
        </div>
    )
}

export default AmplifierConfig;
