import { useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo.png';

function Navbar({ handleSignClick, isSigned }) {
    const [ showRatings, setShowRatings ] = useState(false);
    const [ showCalculations, setShowCalculations ] = useState(false);

    const handleMenuShow = ({ show, type }) => (e) => {
        e.stopPropagation();

        switch (type) {
            case 'ratings':
                setShowRatings(show);
                break;
            case 'calculations':
                setShowCalculations(show);
                break;
            default:
                break;
        }
    }

    return (
        <nav className='flex-nav'>
            <ul>
                <li className='nav-logo'>
                    <Link to='/'>
                        <img alt='logo' src={logo} />
                    </Link>
                </li>
                { isSigned && 
                    <li className='nav-feedbacks'>
                        <Link to='/info' className='element-underline'>Info</Link>
                    </li>
                }
                { isSigned && 
                    <li className='nav-signup'>
                        <Link to='/devices' className='element-underline'>Devices</Link>
                    </li>
                }
                { isSigned && 
                    <li className='nav-signup'>
                        <Link to='/compare' className='element-underline'>Compare</Link>
                    </li>
                }
                { isSigned && 
                    <li 
                        className='nav-feedbacks' 
                        onMouseEnter={handleMenuShow({ show: true, type: 'ratings' })} 
                        onMouseLeave={handleMenuShow({ show: false, type: 'ratings' })}
                    >
                        <div className='ratings'>
                            <span>Ratings</span> 
                            <span style={{ paddingLeft: '10px'}}><FontAwesomeIcon icon={faAngleDown}  size="xs" /></span>
                        </div>
                        { showRatings &&
                            <div className='menu-options'>
                                <Link to='/ratings-all-devices' className='element-underline'>All Devices</Link>
                                <Link to='/ratings-by-size' className='element-underline'>By Size</Link>
                            </div>
                        }
                    </li>
                }
                { isSigned && 
                    <li 
                        className='nav-login'
                        // onMouseEnter={handleMenuShow({ show: true, type: 'calculations' })} 
                        // onMouseLeave={handleMenuShow({ show: false, type: 'calculations' })}
                    >
                        {/* <div className='ratings'>
                            <span>Calculate</span> 
                            <span style={{ paddingLeft: '10px'}}><FontAwesomeIcon icon={faAngleDown}  size="xs" /></span>
                        </div> */}
                        {/* { showCalculations &&
                            <div className='menu-options'>
                                <Link to='/calculations/default/default' className='element-underline'>Speakers</Link>
                                <Link to='/calculations/headphones/default/default' className='element-underline'>Headphones</Link>
                            </div>
                        } */}
                        <Link to={`/calculations/default/default/default/default`} className='element-underline'>Calculate</Link>
                    </li>
                }
                <li className='nav-sign'>
                    { isSigned 
                        ? <button className='btn-black' onClick={handleSignClick('logout')}>Logout</button>
                        : <button className='btn-black' onClick={handleSignClick('login')}>Login</button>
                    }
                </li>
            </ul>
        </nav>
    )
}

export default Navbar;
