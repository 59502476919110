import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import CalculationsTable from "./CalculationsTable.js";
import DevicesListAside from "./DevicesListAside/DevicesListAside.js";
import FilterBox from "../FilterBox/FilterBox.js";
import Loader from "../Loader/Loader.js";

import { 
	getDevices, 
	setFilter, 
	selectDevices,
	selectCompanies,
	selectDevicesStatus,
	selectDevicesFilter,
	selectCurrentDeviceId,
	selectSizeCategoriesArray,
	selectSizeCategoriesObject,
} from "../../reducers/devices.js"

import { 
	createFilterForDevice, 
	findDeviceById,
	findDeviceByCompanyAndCategory,
	toLowerCaseAndReplaceSymbolsWithDash
} from "../../utils/utils.js";

import { 
	getMeasurements, 
	selectMeasurementsStatus 
} from "../../reducers/measurements.js";

function Calculations() {
	
	const { companyName, speakerName, speakerConfig, speakerVolume } = useParams();
	const navigate = useNavigate();

	const dispatch = useDispatch();

    const devicesStatus = useSelector(selectDevicesStatus);
    const measurementsStatus = useSelector(selectMeasurementsStatus);

	const [ all, ...companies ] = useSelector(selectCompanies);
	const filter = useSelector(selectDevicesFilter);
	const currentDeviceId = useSelector(selectCurrentDeviceId); 
	const categories = useSelector(selectSizeCategoriesArray);
	const categoriesObject = useSelector(selectSizeCategoriesObject);
	const allDevices = useSelector(selectDevices);

	useEffect(() => {
		if (devicesStatus === 'idle') {
			dispatch(getDevices())
		}

		if (measurementsStatus === 'idle') {
			dispatch(getMeasurements())
		}

		if (devicesStatus === 'succeded') {
			// names would be 'default' if the user navigated to the page directly so he would be redirected to the first company and speaker
			if (companyName === 'default' && speakerName === 'default') {
				const currentCompany = toLowerCaseAndReplaceSymbolsWithDash(filter.company);
				let currentSpeaker, currentVolume, currentConfig;

				if (filter.devices.length === 0) {
					({ 
						name: currentSpeaker, 
						volume: currentVolume,
						amp_config: currentConfig 
					} = findDeviceByCompanyAndCategory(allDevices, categoriesObject, { company: currentCompany, category: 'All' }));
				} else {
					({ 
						name: currentSpeaker, 
						volume: currentVolume,
						amp_config: currentConfig 
					} = findDeviceById(allDevices, currentDeviceId));
				}

				navigate(`/calculations/${currentCompany}/${currentSpeaker}/${currentConfig}/${currentVolume}`)
			} else {
				const newFilter = createFilterForDevice(allDevices, companyName, speakerName, speakerVolume, speakerConfig);

				if (newFilter === null) {
					navigate('/')
				} else if (newFilter.deviceId !== currentDeviceId) {

					dispatch(setFilter({
						company: newFilter.company,
						category: filter.category,
						deviceId: newFilter.deviceId
					}));
				} 
			}
		}
    }, [ companyName, speakerName, speakerConfig, speakerVolume, allDevices, devicesStatus, measurementsStatus ])

	const handleClick = (type) => (e) => {
		const innerText = e.target.innerText;
		let newFilter = {};

		if (type === 'company') {
			newFilter = {
				company: innerText,
				category: filter.category,
				deviceId: 0
			}
		} else if (type === 'category') {
			newFilter = {
				company: filter.company,
				category: innerText,
				deviceId: 0
			}
		}

		dispatch(setFilter({
			...newFilter
		}));
		
		const device = findDeviceByCompanyAndCategory(allDevices, categoriesObject, newFilter);

		if (device !== null) {
			navigate(`/calculations/${device.company}/${device.name}/${device.amp_config}/${device.volume}`)
		}
	}
		
	if (devicesStatus === 'loading' || measurementsStatus === 'loading') {
        return <Loader />
    } else if (devicesStatus === 'failed' || measurementsStatus === 'failed') {
        return <div className="something-went-wrong">Downloading failed ಠ﹏ಠ</div>
    }

    return (
		<div className='calculations-container'>
			<div className="calculations-container-aside">
				<FilterBox 
					data={companies}
					filter={filter.company}
					handleClick={handleClick('company')}
					className="calculations-companies"
				/>
				<FilterBox 
					data={categories}
					filter={filter.category}
					handleClick={handleClick('category')}
					className="calculations-categories"
				/>
				<DevicesListAside />
			</div>
			<div className="calculations-container-main">
				<CalculationsTable />
			</div>
		</div>
    );
}

export default Calculations;
