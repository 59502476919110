import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGear } from '@fortawesome/free-solid-svg-icons';

import { selectUserRole } from "../../reducers/auth.js";

import AmplifierConfigsList from './AmplifierConfigs/AmplifierConfigsList.js';

function DeviceListItem({ device, showItems, setClicked, setClickedDevice, setClickedDeviceVolume, setPoints }) {
    const navigate = useNavigate();

    const userRole = useSelector(selectUserRole);

    const handleAddNewConfig = (e) => {

        navigate('/add-new-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                header: 'Add New Config'
            }
        }})
    }

    const handleEditDeviceSettings = (e) => {

        navigate('/edit-device', { state: { 
            device: {
                name: device.name,
                company: device.company,
                category: device.category,
                size: device.size,
                volume: '',
                header: 'Edit General Settings'
            }
        }})
    }

    if (!showItems) return;

    return (
        <div >
            <AmplifierConfigsList 
                device={device}
                setClicked={setClicked}
                setClickedDevice={setClickedDevice}
                setClickedDeviceVolume={setClickedDeviceVolume}
                setPoints={setPoints}
            />

            { userRole === 'admin' &&
                <div className='add-new-volume-for-device'>
                    <div className='add-icon' onClick={handleAddNewConfig}>
                        <span>
                            <FontAwesomeIcon icon={faPlus}  size="lg" /> Add New Config
                        </span>
                    </div>
                    <div className='edit-icon' onClick={handleEditDeviceSettings}>
                        <span>
                            <FontAwesomeIcon icon={faGear}  size="lg" /> Edit General
                        </span>
                    </div>
                </div>
            }
        </div>
    )
}

export default DeviceListItem;
