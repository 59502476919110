import { useSelector, useDispatch } from "react-redux";

import { 
	selectCurrentDeviceId,
    selectFilteredDevices,
    setMenuStatus
} from "../../../reducers/devices.js"

import DevicesListAsideHeader from "./DevicesListAsideHeader.js";
import ConfigList from "./ConfigList.js";

function DevicesListAside() {
    const dispatch = useDispatch();

    const currentDeviceId = useSelector(selectCurrentDeviceId); 
    const filteredDevices = useSelector(selectFilteredDevices);

    if (currentDeviceId === 0) return;

    const handleMenuClick = ({ menuStatus, name }) => (e) => {
		if (menuStatus === 'closed') 
			dispatch(setMenuStatus({ menuStatus: 'opened', name })); 
		else if (menuStatus === 'opened') 
			dispatch(setMenuStatus({ menuStatus: 'closed', name })); 
	}

    return (
        <div className='calculations-devices-names'>
            {	
                filteredDevices.map(({ configs, name, menuStatus }) => 
                    <div 
                        key={name} 
                        className={`device-container ${menuStatus}`}
                        onClick={handleMenuClick({ menuStatus, name })}
                    >
                        <DevicesListAsideHeader name={name} menuStatus={menuStatus} />
                        <ConfigList configs={configs} name={name} menuStatus={menuStatus} />
                    </div>
                )
            }
        </div>
    );
}

export default DevicesListAside;
