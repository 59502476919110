import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Provider } from 'react-redux';

import './styles/index.scss';
import App from './App.js';
import Info from './components/Info/Info.js';
import ErrorPage from "./components/Error/ErrorPage.js";
import Home from './components/Home/Home.js';
import PanelForm from './components/PanelForm/PanelForm.js';
import Calculations from './components/Calculations/Calculations.js';
import RatingsBySize from './components/Ratings/RatingsBySize.js';
import RatingsAllDevices from './components/Ratings/RatingsAllDevices.js';
import Compare from './components/Compare/Compare.js';
import Devices from './components/Devices/Devices.js';
import EditSingleDeviceForm from './components/Devices/EditSingleDeviceForm.js'
import OAuthTokenPage from './components/Sign/OAuthTokenPage.js';

import store from './store.js';

// import CalculationsHeadphones from './components/Calculations/Headphones/CalculationsHeadphones.js';

const router = createBrowserRouter([  
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: 'add-new-device',
                element: <PanelForm />
            },
            {
                path: 'calculations',
                element: <Navigate to="/calculations/default/default/default/default" replace={true} />
            },
            {
                path: 'calculations/:companyName/:speakerName/:speakerConfig/:speakerVolume',
                element: <Calculations />,
            },
            // {
            //     path: 'calculations/headphones/:companyName/:headphoneName',
            //     element: <CalculationsHeadphones />,
            // },
            {
                path: 'ratings-all-devices',
                element: <RatingsAllDevices />
            },
            {
                path: 'ratings-by-size',
                element: <RatingsBySize />
            },
            {
                path: 'info',
                element: <Info />
            },
            {
                path: 'compare',
                element: <Compare />
            },
            {
                path: 'devices',
                element: <Devices />
            },
            {
                path: 'edit-device',
                element: <EditSingleDeviceForm />
            }
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: 'oauth',
        element: <OAuthTokenPage />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

