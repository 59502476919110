import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faVolumeHigh } from '@fortawesome/free-solid-svg-icons';

import {  useSelector } from 'react-redux';
import { selectUserRole } from "../../../reducers/auth.js";

function VolumeListItem({ id, volume, device, handleTitleClick, handleDeleteButton, handleEditButton }) {
    const userRole = useSelector(selectUserRole);

    return (
        <div className="volume-list-item">
            <div>
                <FontAwesomeIcon icon={faVolumeHigh} size='sm'/>  {volume} 
            </div>
            <div 
                className="device-name" 
                onClick={handleTitleClick(id, volume)}
            >
                <span className="element-underline-width">{device.name}</span>
            </div>
            { userRole === 'admin' &&
                <div 
                    className="config-button edit" 
                    onClick={handleEditButton(id, volume)}
                >
                    <FontAwesomeIcon icon={faPen} size='sm'/> Edit
                </div>
            }
            { userRole === 'admin' &&
                <div 
                    className="config-button delete"
                    onClick={handleDeleteButton(id, volume)}
                >
                    <FontAwesomeIcon icon={faTrash} size='sm'/> Delete
                </div>
            }
        </div>

    )
}

export default VolumeListItem;
