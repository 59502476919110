
import { useSelector, useDispatch } from "react-redux";

import { 
    handleVolumeTab, 
    selectCurrentVolumeTab, 
    selectFilteredDevices 
} from "../../reducers/compare.js";

function DevicesList() {
    const dispatch = useDispatch();

    const filteredDevices = useSelector(selectFilteredDevices);
    const currentVolumeTab = useSelector(selectCurrentVolumeTab);

    const handleClick = ({ name, ids, configs }) => (e) => {
        dispatch(handleVolumeTab({ name, ids, configs }));
    }

    return (
        <div className="filter-box">
            {
                filteredDevices.map(({ name, ids, configs }) => {
                    return <div 
                            key={name} 
                            className={`filter-box-item ${currentVolumeTab.name === name ? 'active' : ''}`}
                            onClick={handleClick({ name, ids, configs })}
                        >
                            {name}
                        </div>	
                })
            }
        </div>
    );
}

export default DevicesList;
