import AmplifierConfig from './AmplifierConfig.js';

function AmplifierConfigsList({ device, setClicked, setClickedDevice, setClickedDeviceVolume, setPoints }) {
    const { configs } = device;

    return (
        <div className='amplifier-configs-list'>
            {
                configs.map(config => 
                    <AmplifierConfig 
                        key={config.version} 
                        device={device}
                        version={config.version} 
                        ids={config.ids}
                        setClicked={setClicked}
                        setClickedDevice={setClickedDevice}
                        setClickedDeviceVolume={setClickedDeviceVolume}
                        setPoints={setPoints}
                    />
                )
            }
        </div>
    )
}

export default AmplifierConfigsList;
