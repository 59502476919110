import ConfigListVolumes from "./ConfigListVolumes.js";
import ConfigListVolumesHeader from "./ConfigListVolumesHeader.js";

function ConfigList({ configs, name, menuStatus }) {
    return (
        <div className="volume-container configs-list">
            { menuStatus === 'opened' &&
                configs.map(({ version, ids, configMenuStatus }, i) =>
                    <div key={i} className="config-container">
                        <ConfigListVolumesHeader 
                            version={version}
                            name={name}
                            configMenuStatus={configMenuStatus}
                        />
                        <ConfigListVolumes 
                            ids={ids}
                            configMenuStatus={configMenuStatus}
                        />
                    </div>
                )
            }
        </div>
    );
}

export default ConfigList;
